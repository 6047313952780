import React from 'react';
import Typography from '@components/Typography';
import './Hero.scss';
import Button from '@components/v2/Button';
import Container from '@components/Container';
import links from '@constants/links';
import questIcon from './assets/partners/QuestDiagnostics-h.svg';
import boehringerIcon from './assets/partners/BoehringerIngelheim.svg';
import cellarityIcon from './assets/partners/Cellarity.svg';
import phenomicIcon from './assets/partners/PhenomicAI-h.svg';
import cziIcon from './assets/partners/ChanZuckerbergInitiative-partnership.svg';
import radyIcon from './assets/partners/RadyChildrensInstitute-h.svg';
import zifoIcon from './assets/partners/Zifo.svg';
import awsIcon from './assets/partners/AWS-marketplace.svg';

const Hero: React.FC = () => {
  return (
    <div className="TDB-Home__hero">
      <div className="TDB-Home__hero__top-left-gradient" />
      <div className="TDB-Home__hero__top-center-gradient" />
      <Container>
        <div className="TDB-Home__hero__main_content">
          <a href="https://events.tiledb.com/biotechx-europe-2024" target="_blank" rel="noreferrer">
            <div className="TDB-Home__hero__main_content__biotech">
              <div className="TDB-Home__hero__main_content__biotech__calendar">
                <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.42701 6.92505H18.991C18.8514 6.08429 18.4512 5.30846 17.8469 4.70737C17.2428 4.10612 16.465 3.70991 15.6235 3.5747V2.43789C15.6235 2.20882 15.5014 1.99719 15.303 1.88273C15.1047 1.76826 14.8603 1.76826 14.662 1.88273C14.4637 1.99719 14.3415 2.20881 14.3415 2.43789L14.3414 3.50631H7.07655V2.43789C7.07655 2.20882 6.95436 1.99719 6.75604 1.88273C6.55773 1.76826 6.31334 1.76826 6.11502 1.88273C5.9167 1.99719 5.79451 2.20881 5.79451 2.43789V3.5747C4.95304 3.70994 4.17521 4.10612 3.57111 4.70737C2.96686 5.30846 2.56666 6.08429 2.42701 6.92505ZM2.37573 14.4038V8.20709H19.0425V14.4038C19.0425 15.4805 18.6148 16.5131 17.8534 17.2745C17.0921 18.0358 16.0595 18.4635 14.9827 18.4635H6.4355C5.3588 18.4635 4.32613 18.0358 3.56478 17.2745C2.80342 16.5131 2.37573 15.4805 2.37573 14.4038ZM10.3075 14.8566L13.7262 11.4378L13.7262 11.4378C13.8786 11.2743 13.9349 11.043 13.8745 10.8277C13.814 10.6124 13.6457 10.4441 13.4305 10.3837C13.2151 10.3232 12.9839 10.3794 12.8202 10.5318V10.5319L9.8545 13.4977L8.59806 12.2412C8.43436 12.0889 8.20315 12.0326 7.9878 12.093C7.77261 12.1535 7.60434 12.3218 7.54381 12.537C7.48343 12.7523 7.53966 12.9836 7.69205 13.1473L9.40149 14.8567C9.52168 14.9766 9.68451 15.0441 9.85449 15.0441C10.0243 15.0441 10.1873 14.9766 10.3075 14.8567V14.8566Z"
                    fill="#1F2937"
                  />
                </svg>
              </div>
              <Typography fontSize="body" fontWeight="semi-bold" color="neutral-800">
                Join us at BioTechX Europe 2024 • Basel
              </Typography>
              <div className="TDB-Home__hero__main_content__biotech__caret">
                <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M11.4985 7.99856C11.4988 8.16697 11.4653 8.33374 11.4002 8.48903C11.335 8.64431 11.2394 8.78498 11.1189 8.90272L6.52458 13.4354C6.37417 13.583 6.17181 13.6656 5.96106 13.6655C5.75032 13.6654 5.54805 13.5825 5.39782 13.4347C5.32396 13.3621 5.26532 13.2754 5.22531 13.1799C5.18531 13.0843 5.16473 12.9817 5.1648 12.8781C5.16486 12.7745 5.18555 12.672 5.22568 12.5765C5.2658 12.481 5.32454 12.3944 5.39848 12.3218L9.78088 7.99856L5.39848 3.67527C5.32458 3.60269 5.26586 3.51612 5.22576 3.42061C5.18566 3.3251 5.16498 3.22256 5.16492 3.11898C5.16485 3.01539 5.18542 2.91283 5.2254 2.81727C5.26539 2.72171 5.324 2.63507 5.39782 2.5624C5.54804 2.4146 5.75031 2.33172 5.96105 2.33159C6.1718 2.33147 6.37416 2.41411 6.52457 2.56173L11.1189 7.09423C11.2393 7.21202 11.335 7.35271 11.4001 7.50802C11.4653 7.66334 11.4988 7.83012 11.4985 7.99856Z"
                    fill="#1F2937"
                  />
                </svg>
              </div>
            </div>
          </a>
          <Typography className="TDB-Home__hero__main_content__title" fontSize="display-1" fontWeight="bold" align="center">
            Unlock insights from multimodal data to fuel precision medicine
          </Typography>
          <Typography className="TDB-Home__hero__main_content__description" align="center" fontSize="subheading-1" fontWeight="semi-bold">
            TileDB empowers science and data teams to catalog, collaborate on and analyze all their structured and "unstructured" data to
            accelerate drug and target discovery breakthroughs
          </Typography>
          <div className="TDB-Home__hero__main_content__actions">
            <a href={links.console.signup}>
              <Button variant="primary">Try for free</Button>
            </a>
            <a href={links.requestDemo}>
              <Button variant="brand-secondary">Book a discovery call</Button>
            </a>
          </div>
        </div>
        <div className="TDB-Home__hero__separator">
          <div className="TDB-Home__hero__separator__line" />
        </div>
        <div className="TDB-Home__hero__partners">
          <Typography className="TDB-Home__hero__partners__title" fontSize="overline" fontWeight="bold" align="center">
            TRUSTED CUSTOMERS & PARTNERS
          </Typography>
          <div className="TDB-Home__hero__partners__logos-wrapper">
            <div className="TDB-Home__hero__partners__logos">
              <div className="TDB-Home__hero__partners__logos__item">
                <img src={questIcon} alt="quest-diagnostics" className="TDB-Home__hero__partners__quest" />
              </div>
              <div className="TDB-Home__hero__partners__logos__item">
                <img src={radyIcon} alt="rady-childrens-institute" className="TDB-Home__hero__partners__rady" />
              </div>
              <div className="TDB-Home__hero__partners__logos__item">
                <img src={cziIcon} alt="chan-zuckerberg-initiative" className="TDB-Home__hero__partners__czi" />
              </div>
              <div className="TDB-Home__hero__partners__logos__item">
                <img src={boehringerIcon} alt="boehringer-ingelheim" className="TDB-Home__hero__partners__boehringer" />
              </div>
            </div>
            <div className="TDB-Home__hero__partners__logos">
              <div className="TDB-Home__hero__partners__logos__item">
                <img src={cellarityIcon} alt="cellarity" className="TDB-Home__hero__partners__cellarity" />
              </div>
              <div className="TDB-Home__hero__partners__logos__item">
                <img src={phenomicIcon} alt="phenomic-ai" className="TDB-Home__hero__partners__phenomic" />
              </div>
              <div className="TDB-Home__hero__partners__logos__item">
                <img src={awsIcon} alt="aws" className="TDB-Home__hero__partners__aws" />
              </div>
              <div className="TDB-Home__hero__partners__logos__item">
                <img src={zifoIcon} alt="zifo" className="TDB-Home__hero__partners__zifo" />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Hero;
