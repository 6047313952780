import './Revolutionize.scss';
import React from 'react';
import Typography from '@components/Typography';
import Container from '@components/Container';
import SectionTitle from '../components/SectionTitle';
import tiledbLogo from './assets/tiledb_logo.svg';
import tiledbLogoFull from './assets/tiledb_logo_full.svg';
import bigQuery from './assets/Google-BigQuery.svg';
import snowFlake from './assets/Snowflake-logo.svg';
import databricks from './assets/Databricks.svg';
import velsera from './assets/Velsera.svg';
import codeOcean from './assets/CodeOcean.svg';
import dnaNexus from './assets/DNANexus.svg';
import box from './assets/Box.svg';
import googleDrive from './assets/GoogleDrive.svg';
import dropbox from './assets/Dropbox.svg';
import alation from './assets/Alation.svg';
import atlan from './assets/Atlan.svg';
import collibra from './assets/Collibra.svg';

const Revolutionize: React.FC = () => {
  return (
    <div className="TDB-Revolutionize">
      <SectionTitle
        title="Scientific discovery needs a new data platform"
        description="TileDB consolidates all the structured and unstructured data used in scientific discovery in a single secure place, combining the
          strengths of popular data systems while avoiding their limitations."
      />
      <Container>
        <div className="TDB-SolutionIdeasWrapper">
          <img className="TDB-SolutionIdeas__logo-full" src={tiledbLogoFull} alt="TileDB logo" />
          <div className="TDB-SolutionIdeas">
            {/* Item */}
            <div className="TDB-SolutionIdeas__item">
              <div className="TDB-SolutionIdeas__quote">
                <div className="TDB-SolutionIdeas__header">
                  <Typography className="TDB-SolutionIdeas__subtitle TDB-SolutionIdeas__subtitle--blue">THE POWER OF A</Typography>
                  <Typography as="h3" fontSize="heading-3" fontWeight="bold" color="neutral-900">
                    Database
                  </Typography>
                </div>
                <Typography className="TDB-SolutionIdeas__description" fontSize="heading-5" color="neutral-600" fontWeight="medium">
                  with the ability to handle complex unstructured data
                </Typography>
              </div>

              <div className="TDB-SolutionIdeas__footer">
                <Typography color="neutral-600" fontWeight="semi-bold">
                  Instead of
                </Typography>
                <div className="TDB-SolutionIdeas__icons">
                  <img src={bigQuery} alt="big-query" />
                  <img src={snowFlake} alt="snowFlake" />
                  <img src={databricks} alt="snowFlake" />
                </div>
              </div>
            </div>
            {/* End of Item */}
            <div className="TDB-SolutionIdeas__item TDB-SolutionIdeas__item--right TDB-SolutionIdeas__item--green">
              <div className="TDB-SolutionIdeas__quote">
                <div className="TDB-SolutionIdeas__header">
                  <Typography className="TDB-SolutionIdeas__subtitle TDB-SolutionIdeas__subtitle--green">THE EXPERTISE OF A</Typography>
                  <Typography as="h3" fontSize="heading-3" fontWeight="bold" color="neutral-900">
                    Scientific Solution
                  </Typography>
                </div>
                <Typography className="TDB-SolutionIdeas__description" fontSize="heading-5" color="neutral-600" fontWeight="medium">
                  with data versatility and scalability
                </Typography>
              </div>

              <div className="TDB-SolutionIdeas__footer">
                <Typography className="TDB-SolutionIdeas__footer-note" color="neutral-600" fontWeight="semi-bold">
                  Instead of
                </Typography>
                <div className="TDB-SolutionIdeas__icons">
                  <img src={velsera} alt="velsera" />
                  <img src={codeOcean} alt="code-ocean" />
                  <img src={dnaNexus} alt="dna-nexus" />
                </div>
              </div>
            </div>
            {/* End of Item */}

            <div className="TDB-SolutionIdeas__item TDB-SolutionIdeas__item--bottom TDB-SolutionIdeas__item--magenta">
              <div className="TDB-SolutionIdeas__quote">
                <div className="TDB-SolutionIdeas__header">
                  <Typography className="TDB-SolutionIdeas__subtitle TDB-SolutionIdeas__subtitle--magenta">THE GENERALITY OF A</Typography>
                  <Typography as="h3" fontSize="heading-3" fontWeight="bold" color="neutral-900">
                    File manager
                  </Typography>
                </div>
                <Typography className="TDB-SolutionIdeas__description" fontSize="heading-5" color="neutral-600" fontWeight="medium">
                  with scientific content and built-in compute
                </Typography>
              </div>

              <div className="TDB-SolutionIdeas__footer">
                <Typography color="neutral-600" fontWeight="semi-bold">
                  Instead of
                </Typography>
                <div className="TDB-SolutionIdeas__icons">
                  <img src={box} alt="box" />
                  <img src={googleDrive} alt="google-drive" />
                  <img src={dropbox} alt="dropbox" />
                </div>
              </div>
            </div>
            {/* End of Item */}
            <div className="TDB-SolutionIdeas__item TDB-SolutionIdeas__item--right TDB-SolutionIdeas__item--bottom TDB-SolutionIdeas__item--blue">
              <div className="TDB-SolutionIdeas__quote">
                <div className="TDB-SolutionIdeas__header">
                  <Typography className="TDB-SolutionIdeas__subtitle TDB-SolutionIdeas__subtitle--purple">THE DISCIPLINE OF A</Typography>
                  <Typography as="h3" fontSize="heading-3" fontWeight="bold" color="neutral-900">
                    Data Catalog
                  </Typography>
                </div>
                <Typography className="TDB-SolutionIdeas__description" fontSize="heading-5" color="neutral-600" fontWeight="medium">
                  with the muscle and domain expertise of a database
                </Typography>
              </div>

              <div className="TDB-SolutionIdeas__footer">
                <Typography className="TDB-SolutionIdeas__footer-note" color="neutral-600" fontWeight="semi-bold">
                  Instead of
                </Typography>
                <div className="TDB-SolutionIdeas__icons">
                  <img src={alation} alt="alation" />
                  <img src={atlan} alt="atlan" />
                  <img src={collibra} alt="collibra" />
                </div>
              </div>
            </div>
            {/* End of Item */}
            <div className="TDB-SolutionIdeas__eclipse">
              <img className="TDB-SolutionIdeas__logo" src={tiledbLogo} alt="tiledb logo" />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Revolutionize;
